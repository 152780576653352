import React, { useEffect, useState } from 'react';

const getMobileOperatingSystem = () => {
  if (typeof window === 'undefined') return 'others';
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return 'others';
};
const AppDownload = () => {
  const [os, setOs] = useState('others');
  useEffect(() => {
    setOs(getMobileOperatingSystem());
  }, []);
  if (typeof window !== 'undefined') {
    const params = window.location.href.replace(window.location.origin, '').replace(window.location.pathname, '').replace('?', '');
    if (os === 'android') {
      window.location.href = `https://play.google.com/store/apps/details?id=com.komunal.depositobpr&referrer=${encodeURIComponent(params)}`;
    } else if (os === 'ios') {
      window.location.replace('kombpr://');
      setTimeout(() => {
        window.location.href = 'https://apps.apple.com/us/app/depositobpr/id1593828144';
      }, 3000);
    } else {
      window.location = '/';
    }
  }

  return (
    <div />
  );
};

export default AppDownload;
